import { template as template_5196e9fb8d3a48a3b7e824329a710b1b } from "@ember/template-compiler";
const FKControlMenuContainer = template_5196e9fb8d3a48a3b7e824329a710b1b(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
