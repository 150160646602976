import { template as template_ad44d8199c4347dcb7311f083a369e14 } from "@ember/template-compiler";
const FKText = template_ad44d8199c4347dcb7311f083a369e14(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
